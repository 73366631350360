
import webcamRoutes from '@/modules/Resorts/Routes/sections/webcamRoutes';
import mapRoutes from '@/modules/Resorts/Routes/sections/mapRoutes';
import tracksRoutes from "@/modules/Resorts/Routes/sections/tracksRoutes";
import contentRoutes from '@/modules/Resorts/Routes/sections/contentRoutes';
import photosRoutes from "@/modules/Resorts/Routes/sections/photosRoutes";
import touristicObjectsRoutes from "@/modules/Resorts/Routes/sections/touristicObjectsRoutes";
import zonesRoutes from "@/modules/Resorts/Routes/sections/zonesRoutes";
import {getResortPanelTitle} from "@/modules/Resorts/Helpers/routeHelper";
import thrillsRoutes from "@/modules/Resorts/Routes/sections/thrillsRoutes";
import audiencesRoutes from "@/modules/Resorts/Routes/sections/audiencesRoutes";
import requirementsRoutes from "@/modules/Resorts/Routes/sections/requirementsRoutes";

let initRoutes = [
  {
    path: '',
    name: 'manage-resort',
    component: () => import('@/modules/Resorts/Pages/ResortMenu.vue'),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.manage.manage',
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid', active: true },
      ]
    },
  },
  {
    path: 'snow-report',
    name: 'snow-report',
    component: () =>
    import(
      /* webpackChunkName: "snowReport" */ "@/modules/Resorts/Pages/snowreport/SnowReport.vue"
    ),
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.snowreport.title',
      drm: [
        { context: 'resorts', drm: 'report' }
      ],
      breadcrumb: [
          { title: 'Home', url: '/' },
          { title: getResortPanelTitle, url: '/resorts/:uuid' },
          { title: 'resorts.snowreport.title', active: true },
      ]
    }
  },
  {
    path: 'content',
    name: 'content',
    component: () => import(
      /* webpackChunkName: "contents" */ "@/modules/Resorts/Pages/content/Contents.vue"
    ),
    props: {
      path: 'resort/pages/index',
    },
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.content.title',
      drm: [
        { context: 'resorts', drm: 'editor' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.content.title', active: true },
      ]
    }
  },
  {
    path: '#',//ES REESCRIU EN ResortMenu.Vue
    name: 'pois',
    props: {
      target: '_blank',
    },
    meta: {
      parent: "resorts",
      pageTitle: 'resorts.pois.title',
      drm: [
        { context: 'resorts', drm: 'osm' }
      ],
      breadcrumb: [
        { title: 'Home', url: '/' },
        { title: getResortPanelTitle, url: '/resorts/:uuid' },
        { title: 'resorts.pois.title', active: true },
      ]
    }
  }
]

export let resortsRoutes = [
  ...initRoutes,
  ...webcamRoutes,
  ...mapRoutes,
  ...tracksRoutes,
  ...contentRoutes,
  ...photosRoutes,
  ...touristicObjectsRoutes,
  ...zonesRoutes,
  ...thrillsRoutes,
  ...audiencesRoutes,
  ...requirementsRoutes,
 ];
