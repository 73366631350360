import store from "@/modules/Shared/Store/store";
import i18n from "@/i18n";
import adminChallengeRoutes from "@/modules/Admin/Routes/sections/adminChallengeRoutes";

let initRoutes = [
    {
      path: '/admin',
      name: 'admin-dashboard',
      component: () => import('@/modules/Admin/Pages/AdminMenu.vue'),
      meta: {
        parent: "admin",
        pageTitle: 'admin.manage.manage',
      },
    },
    {
        path: '/admin/translations',
        name: 'admin-translations',
        component: () => import('@/modules/Admin/Pages/TranslationsPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.translations.title',
            breadcrumb: [
                { title: 'admin.translations.title', url: `/admin/translations`, active: true }
            ]
        },
    },
    {
        path: '/admin/app-features',
        name: 'admin-app-features',
        component: () => import('@/modules/Admin/Pages/AppFeaturesPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.app-features.title',
            breadcrumb: [
                { title: 'admin.app-features.title', url: `/admin/app-features`, active: true }
            ]
        },
    },
    {
        path: '/admin/app-features/create',
        name: 'admin-app-scope-create',
        component: () => import('@/modules/Admin/Pages/CreateAppScopePage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.app-features.create.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.app-features.title', url: `/admin/app-features`},
                { title: 'shared.add', url: `/admin/app-features/create`, active: true }
            ]
        },
    },
    {
        path: '/admin/app-features/:id/edit',
        name: 'admin-app-scope-edit',
        component: () => import('@/modules/Admin/Pages/EditAppScopePage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.app-features.edit.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.app-features.title', url: `/admin/app-features`},
                {
                    title: () => {
                        const scope = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${scope.name}`
                    },
                    active: true
                },
            ]
        },
    },

    {
        path: '/admin/roles',
        name: 'admin-roles',
        component: () => import('@/modules/Admin/Pages/RolesPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.roles.title',
            breadcrumb: [
                { title: 'admin.roles.title', url: `/admin/roles`, active: true }
            ]
        },
    },
    {
        path: '/admin/roles/create',
        name: 'admin-role-create',
        component: () => import('@/modules/Admin/Pages/CreateRolePage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.roles.create.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.roles.title', url: `/admin/roles`},
                { title: 'shared.add', url: `/admin/roles/create`, active: true }
            ]
        },
    },
    {
        path: '/admin/roles/:id/edit',
        name: 'admin-role-edit',
        component: () => import('@/modules/Admin/Pages/EditRolePage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.roles.edit.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.roles.title', url: `/admin/roles`},
                {
                    title: () => {
                        const role = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${role.name}`
                    },
                    active: true
                },
            ]
        },
    },

    {
        path: '/admin/users',
        name: 'admin-users',
        component: () => import('@/modules/Admin/Pages/UsersPage.vue'),
        meta: {
            parent: "admin",
            pageTitle: 'admin.users.title',
            breadcrumb: [
                { title: 'admin.users.title', url: `/admin/users`, active: true }
            ]
        },
    },
    {
        path: '/admin/users/create',
        name: 'admin-user-create',
        component: () => import('@/modules/Admin/Pages/CreateUserPage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.users.create.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.users.title', url: `/admin/users`},
                { title: 'shared.add', url: `/admin/users/create`, active: true }
            ]
        },
    },
    {
        path: '/admin/users/:uuid/edit',
        name: 'admin-user-edit',
        component: () => import('@/modules/Admin/Pages/EditUserPage.vue'),
        props: (route) => ({
            ...route.params
        }),
        meta: {
            parent: "admin",
            pageTitle: 'admin.users.edit.title',
            breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'admin.users.title', url: `/admin/users`},
                {
                    title: () => {
                        const user = store.getters.routeElement
                        return `${i18n.t('datatable.button.edit')} ${user.username}`
                    },
                    active: true
                },
            ]
        },
    },
];

export let adminRoutes = [
  ...initRoutes,
  ...adminChallengeRoutes,
];
